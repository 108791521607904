<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
      <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
    </div>
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Member Details</h3>
            </v-col>
            <v-col align="center" cols="12" sm="6" lg="3" md="3">
              <img width="100" height="100" :src="row.ProfilePic" />
              <p>
                {{ row.MembershipId }}
                <br />
                {{ row.MemberNameTxt }}
                <br />
                {{ row.ZoneName }} - {{ row.LomName }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Member Type</h5>
              <p class="preview-content">{{ row.MemberTypeName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Mobile No</h5>
              <p class="preview-content">{{ row.MobileNo }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Email Id</h5>
              <p class="preview-content">{{ row.EmailId }}</p>
            </v-col>
          </v-row>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h3 class="red--text text--lighten">
                  Title Payment Details - {{ row.TitleName }}
                </h3>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h5 class="blue--text text--lighten">
                  <span class="text-danger">*</span> Paid Date
                </h5>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="row.PaidDateTxt"
                      label="Paid Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="row.PaidDateTxt"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <h5 class="blue--text text--lighten">
                  <span class="text-danger">*</span> LMC Register No
                </h5>
                <v-text-field
                  v-model="row.RegisterNo"
                  :rules="RegisterNoRules"
                  hint="Enter the LMC Register No"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <h5 class="blue--text text--lighten">
                  <span class="text-danger">*</span> LMC Receipt No
                </h5>
                <v-text-field
                  v-model="row.ReceiptNo"
                  :rules="ReceiptNoRules"
                  hint="Enter the Receipt No"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <h5 class="blue--text text--lighten">
                  <span class="text-danger">*</span> Paid Amount
                </h5>
                <v-text-field
                  v-model="row.OrderAmount"
                  :rules="PaidAmountRules"
                  hint="Enter the paid amount"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    PageTitle: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ProgressFlag: false,
      TableLoadingFlag: false,
      ResultFlag: false,

      row: {},

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      RegisterNo: "",
      RegisterNoRules: [(v) => !!v || "Register No is required"],

      ReceiptNo: "",
      ReceiptNoRules: [(v) => !!v || "Receipt No is required"],

      PaidAmount: "",
      PaidAmountRules: [(v) => !!v || "Paid amount is required"],

      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
    };
  },
  mounted() {},
  watch: {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      console.log("recordData=" + JSON.stringify(this.recordData));
      this.row = this.recordData;
    },
    submitForm() {
      console.log("submitForm is called");

      var tr = this.recordData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      // n1 = 0;

      if (validate1 > 0) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/offline-title-update";
        var upload = {
          UserInterface: 1,
          TitleMember: tr.TMRelationId,
          Title: tr.TitleId,
          Member: tr.MemberId,
          PaidDate: tr.PaidDateTxt,
          PaidAmount: tr.OrderAmount,
          RegisterNo: tr.RegisterNo,
          ReceiptNo: tr.ReceiptNo,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.SearchFlag = false;
            // thisIns.ResultFlag = true;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log("records=" + records);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>