<template>
  <div id="lom-list">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-container class="py-0" v-if="tableOptions1.FloatingButtonFlag">
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click.prevent="addRecord"
                v-if="tableOptions1.CreateButtonFlag"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click.prevent="TransferData"
                v-if="tableOptions1.TransferFlag"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-repeat</v-icon>
              </v-btn>
            </template>
            <span>Transfer</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="green"
                v-if="tableOptions1.PreviewButtonFlag"
                @click.prevent="previewData"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-search</v-icon>
              </v-btn>
            </template>
            <span>Preview</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="green"
                v-if="tableOptions1.EditButtonFlag"
                @click.prevent="editData"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="red"
                v-if="tableOptions1.DeleteButtonFlag"
                @click.prevent="deleteDataAlert"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>

      <v-dialog v-model="previewRecordPrompt" max-width="75%">
        <foundation-payment-preview
          PageTitle="Foundation Payment Details"
          :previewRecordPrompt="previewRecordPrompt"
          @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
          :recordData="selectedData"
          v-if="previewRecordPrompt"
        ></foundation-payment-preview>
      </v-dialog>

      <v-dialog v-model="TransferRecordPrompt" max-width="75%">
        <foundation-title-transfer
          pageTitle="Foundation Payment Transfer"
          :TransferRecordPrompt="TransferRecordPrompt"
          @hideTransferRecordPrompt="hideTransferRecordPrompt"
          :recordData="selected"
          v-if="TransferRecordPrompt"
        ></foundation-title-transfer>
      </v-dialog>

      <v-dialog v-model="editRecordPrompt" max-width="75%">
        <manual-foundation-payment-edit
          PageTitle="Manual Foundation Payment Edit"
          :previewRecordPrompt="editRecordPrompt"
          @hidePreviewRecordPrompt="hideEditRecordPrompt($event)"
          :recordData="selectedData"
          v-if="editRecordPrompt"
        ></manual-foundation-payment-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span> Search Type</label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="SearchIdOptions"
                  :rules="SearchIdRules"
                  v-model="SearchId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>
                  Zone
                  <small>(optional)</small>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="ZoneCode"
                  :rules="ZoneCodeRules"
                  :items="ZoneCodeOptions"
                  :loading="ZoneCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>
                  LOM
                  <small>(optional)</small>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="LomCode"
                  :rules="LomCodeRules"
                  :items="LomCodeOptions"
                  :loading="LomCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 2">
                <label
                  ><span class="text-danger">*</span> LMC Register Number</label
                >
                <v-text-field
                  v-model="RegisterNo"
                  :rules="RegisterNoRules"
                  hint="Enter LMC Register number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 3">
                <label
                  ><span class="text-danger">*</span> Razorpay payment id</label
                >
                <v-text-field
                  v-model="PaymentId"
                  :rules="PaymentIdRules"
                  hint="Enter razorpay payment id"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>* Zone</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>-->
              <v-col cols="12" md="3" v-if="SearchId == 4">
                <label><span class="text-danger">*</span> Membership id</label>
                <v-text-field
                  v-model="MembershipId"
                  :rules="MembershipIdRules"
                  hint="Enter Membership id"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>Transaction Type <small>(optional)</small></label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="TransactionTypeIdOptions"
                  :rules="TransactionTypeIdRules"
                  v-model="TransactionTypeId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>From Date</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      label="From Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FromDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" v-if="SearchId == 1">
                <label>To Date</label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      label="To Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ToDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag && rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="tableOptions1.ExcelDownloadFlag"
                @click.prevent="generateReportExcel"
                :loading="ExcelLoadingFlag"
                target="_blank"
                color="#ff7f50"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                Generate Report
              </v-btn>
              <v-btn
                v-if="ExcelFileName != '' && ExcelDownloadFlag == 1"
                :href="ExcelFileName"
                target="_blank"
                color="#2fb65a"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                <v-icon dark>mdi-download</v-icon>Download Report
              </v-btn>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                color="#008000"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                v-if="
                  rows.length > 0 &&
                  tableOptions1.JsonDownloadButtonFlag == true
                "
                @click.prevent="
                  generateExcel(
                    rows,
                    tableOptions1.JsonExcelFields,
                    tableOptions1.ExcelFileName,
                    'My Worksheet'
                  )
                "
                >Download</v-btn
              >
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :loading="SearchFlag"
                loading-text="Loading... Please wait"
                :headers="tableColumns1"
                :items="rows"
                :search="search"
                :items-per-page="tableOptions1.ItemsPerPage"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :show-select="tableOptions1.ShowSelectFlag"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.ReceiptFilePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.ReceiptFilePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#00f"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-search</v-icon
                      >
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.ActiveStatusTxt == 2"
                        >mdi-file-document-edit</v-icon
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import FoundationPaymentPreview from "@/view/pages/erp/reports/FoundationPayment/FoundationPaymentPreview.vue";
import FoundationTitleTransfer from "@/view/pages/erp/reports/FoundationPayment/FoundationTitleTransfer.vue";
import ManualFoundationPaymentEdit from "@/view/pages/erp/reports/FoundationPayment/ManualFoundationPaymentEdit.vue";

export default {
  mixins: [common],
  components: {
    FoundationPaymentPreview,
    FoundationTitleTransfer,
    ManualFoundationPaymentEdit,
  },
  data() {
    return {
      valid1: true,
      valid2: true,

      DeleteLoadingFlag: false,
      LoadingFlag: false,
      ResultFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],
      tableColumns1: [],
      tableOptions1: {},
      selected: [],
      selectedData: {},
      search: "",
      CurrentYearId: "",

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By Date" },
        { value: 2, text: "By LMC Register number" },
        { value: 3, text: "By Razorpay Payment id" },
        { value: 4, text: "By Membership id" },
      ],

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      TransactionTypeId: "",
      TransactionTypeIdRules: [],
      // (v) => !!v || "Transaction Type is required"
      TransactionTypeIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "Online" },
        { value: 2, text: "Manual" },
      ],

      RegisterNo: "",
      RegisterNoRules: [(v) => !!v || "LMC Register number is required"],

      PaymentId: "",
      PaymentIdRules: [(v) => !!v || "Payment id is required"],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership id is required"],

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      TransferRecordPrompt: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCode = "";
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.LomCode = "";
      this.getLomCodeOptions();
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
      this.LomCode = "";
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    TransferData() {
      console.log("TransferData called");
      // var tr = this.selected
      // var n1 = tr.length
      var selectedData = this.selected;
      var n1 = selectedData.length;

      console.log(
        "n1=" + n1 + ", selectedData=" + JSON.stringify(selectedData)
      );
      if (n1 > 0) {
        this.selected = selectedData[0];
        this.TransferRecordPrompt = true;
      } else {
        var message = "Kindly select one record to Transfer";
        this.toast("error", message);
      }
    },
    hideTransferRecordPrompt() {
      console.log("hideTransferRecordPrompt called");
      this.TransferRecordPrompt = false;
      this.searchForm();
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lmc/title/contribution-payment";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCode = "";
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    editData() {
      var selectedData = this.selected;
      console.log("previewData called");
      var n1 = selectedData.length;
      console.log({ selectedData });
      var message = "";
      if (n1 > 0) {
        var tr = selectedData[0];
        console.log({ tr });
        var TransactionTypeId = tr.TransactionTypeId;
        console.log({ TransactionTypeId });
        if (TransactionTypeId == 2) {
          this.editRecordPrompt = true;
          this.selectedData = tr;
        } else {
          message = "You can not edit Online title payment";
          this.sweetAlert("error", message, false);
        }
      } else {
        message = "Kindly select one member to edit";
        this.sweetAlert("error", message, true);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      console.log({ flag });
      this.editRecordPrompt = false;
      this.selected = [];
      if (flag) {
        this.searchForm();
      }
    },
    previewData() {
      var selectedData = this.selected;
      console.log("previewData called");
      var n1 = selectedData.length;
      console.log({ selectedData });
      if (n1 > 0) {
        var tr = selectedData[0];
        console.log({ tr });
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
      this.selected = [];
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "foundation_payment",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lmc/title-payment/lists";
        var upload = {
          SearchType: this.SearchId,
          RegisterNo: this.RegisterNo,
          TransactionType: this.TransactionTypeId,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
          PaymentId: this.PaymentId,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          MembershipId: this.MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            // thisIns.ExcelFields = records.ExcelFields;
            // thisIns.ExcelFileName = records.ExcelFileName;

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableOptions1 = records.TableOptions;
              thisIns.rows = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
      // this.generateReportExcel();
    },
    deleteDataAlert() {
      console.log("deleteDataAlert called");
      var tr = this.selected;
      var n1 = tr.length;
      //   var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr[0];
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      //   var validate1 = this.$refs.form1.validate();
      //   console.log("validate1=" + validate1);
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "Member Name <b> " + tr.MemberName + "</b>";
        htmlTxt += "<br/> Title <b> " + tr.TitleName + "</b>";
        htmlTxt += "<br/> LMC Register No <b> " + tr.RegisterNo + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteData() {
      console.log("deleteData is called");

      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lmc/title-payment/delete";
        var upload = {
          UserInterface: 1,
          MemberName: tr.TMRelationId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.SearchFlag = false;
            // thisIns.ResultFlag = true;
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.selectedData = {};
              thisIns.selected = [];
              thisIns.searchForm();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      var validate1 = this.$refs.form2.validate();
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/receipt/excel/export";
        var upload = {
          UserInterface: 2,
          ZoneCode: this.ZoneCode,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {
    // this.searchForm();
  },
  beforeMount() {
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    this.FromDate = todayDate;
    this.ToDate = todayDate;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>