<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
      <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
    </div>
    <v-app-bar color="primary" dense dark elevate-on-scroll scroll-target="#scrolling-techniques-7">
      <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Member Details</h3>
            </v-col>
            <v-col align="center" cols="12" sm="6" lg="3" md="3">
              <img width="100" height="100" :src="row.ProfilePic" />
              <p>
                {{ row.MembershipId }}
                <br />
                {{ row.MemberNameTxt }}
                <br />
                {{ row.ZoneName }} - {{ row.LomName }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Member Type</h5>
              <p class="preview-content">{{ row.MemberTypeName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Mobile No</h5>
              <p class="preview-content">{{ row.MobileNo }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Email Id</h5>
              <p class="preview-content">{{ row.EmailId }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Aadhar Number</h5>
              <p class="preview-content">{{ row.AadharNumber }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">PAN Number</h5>
              <p class="preview-content">{{ row.GstNumber }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Need Income Tax Claim</h5>
              <p class="preview-content">{{ row.ClaimTaxTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="row.ClaimTax == 1">
              <h5 class="blue--text text--lighten">PanCard Image</h5>
              <img width="100" height="100" :src="row.PanImagePath" />
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Title Payment Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Paid Date</h5>
              <p class="preview-content">{{ row.PaidDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Paid Amount</h5>
              <p class="preview-content">{{ row.OrderAmount }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Payment Method</h5>
              <p class="preview-content">{{ row.PaymentMethod }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Rzrpay Id</h5>
              <p class="preview-content">{{ row.PaymentId }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Register No</h5>
              <p class="preview-content">{{ row.RegisterNo }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Type</h5>
              <p class="preview-content">{{ row.TypeTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Title Name</h5>
              <p class="preview-content">{{ row.TitleName }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      required: true
    },
    PageTitle: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ProgressFlag: false,
      TableLoadingFlag: false,

      row: {},

      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");

      console.log("recordData=" + JSON.stringify(this.recordData));
      this.row = this.recordData;
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>